import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import "./App.css";
import Home from "./pages/Home";
import Addresses from "./pages/Addresses";
import Schedule from "./pages/Schedule";
import TravelAndLodging from "./pages/TravelAndLodging";
import WeddingParty from "./pages/WeddingParty";
import FAQs from "./pages/FAQs";
import ThingsToDo from "./pages/ThingsToDo";
import Registry from "./pages/Registry";
import RSVP from "./pages/RSVP";
import { ParallaxProvider } from "react-scroll-parallax";

const App: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <ParallaxProvider>
      <Router>
        <header className="navbar">
          <div className="navbar-title tangerine-bold">
            <Link to="/" className="no-link">
              The Jameses
            </Link>
          </div>
          <nav className={`navbar-links ${isMenuOpen ? "active" : ""}`}>
            <Link onClick={toggleMenu} to="/">
              Home
            </Link>
            <Link onClick={toggleMenu} to="/addresses">
              Addresses
            </Link>
            <Link onClick={toggleMenu} to="/schedule">
              Schedule
            </Link>
            <Link onClick={toggleMenu} to="/travel">
              Travel and Lodging
            </Link>
            <Link onClick={toggleMenu} to="/wedding-party">
              Wedding Party
            </Link>
            <Link onClick={toggleMenu} to="/faqs">
              FAQs
            </Link>
            <Link onClick={toggleMenu} to="/things-to-do">
              Things To Do
            </Link>
            <Link onClick={toggleMenu} to="/registry">
              Registry
            </Link>
            <Link onClick={toggleMenu} to="/rsvp">
              RSVP
            </Link>
          </nav>
          <div className="hamburger" onClick={toggleMenu}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </header>

        <div className="parallax-background">
          <div className="foreground-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/addresses" element={<Addresses />} />
              <Route path="/schedule" element={<Schedule />} />
              <Route path="/travel" element={<TravelAndLodging />} />
              <Route path="/wedding-party" element={<WeddingParty />} />
              <Route path="/faqs" element={<FAQs />} />
              <Route path="/things-to-do" element={<ThingsToDo />} />
              <Route path="/registry" element={<Registry />} />
              <Route path="/rsvp" element={<RSVP />} />
            </Routes>
          </div>
        </div>
      </Router>
    </ParallaxProvider>
  );
};

export default App;
