import React from 'react';
import PageUnderConstructionNotice from "../components/underConstruction";

const TravelAndLodging: React.FC = () => {
  return (
    <div className="text-content">
      <div className="simple-card">
        <PageUnderConstructionNotice
          image="https://img.freepik.com/free-vector/illustrated-andalusia-map-with-interesting-landmarks_23-2148631295.jpg?t=st=1727751250~exp=1727754850~hmac=c4e3f1a65595bdf788a2b33192bc96dd2f969876ae7565bad206ef94995e8067&w=1060"
          title="Travel and Lodging is still a work in progress!"
          message="More hotel and lodging information to come, as soon as Lauren closes this deal at work... For now:"
        />
        <div className="text-start">
          <h2>Travel</h2>
          Marbella is in southern Spain; it's a popular destination but doesn't
          have its own major international airport. The easiest ways to reach
          Marbella typically involve flying into Málaga or another major Spanish
          or European airport, then continuing on to Marbella by either a
          connecting flight, bus, or car. Here are some of the easiest routes:
          <br />
          <ol>
            <li className="mt-4">
              ✈️ <strong>Fly to Málaga (Costa del Sol) Airport (AGP)</strong>.
              The most convenient option is to fly into Málaga-Costa del Sol
              Airport (AGP), which is the nearest major airport to Marbella,
              located about 50 kilometers (30 miles) away.
              <br />
              <br />
              ✈️ ➡️ 🛩️
              <br />
              There are no direct flights from the USA to Málaga, but you can
              find connecting flights via major European hubs like Madrid (MAD),
              London (LHR), Paris (CDG), Barcelona (BCN), or Lisbon (LIS).
            </li>
            <li className="mt-4">
              ✈️ <strong>Fly to Sevilla (SVQ).</strong> Fly into Sevilla Airport
              (SVQ), then connect to Marbella.
              <br />
              <br />
              🚅 Train: Take a high-speed AVE train from Sevilla to Malaga (~2
              hours). From Malaga, take a bus or a private transfer to Marbella.
              <br />
              <br />
              🛩️ Domestic Flight: There typically aren't direct flights from
              Sevilla (SVQ) to Málaga, but you can take a connecting flight or
              train / car, since it's only 97 miles.
              <br />
              <br />
              🚌 Bus or 🚙 Car: Consider this if you plan to explore the Costa
              del Sol area during your stay. ~2.5 hours from Sevilla Airport
              (SVQ) to Marbella.
            </li>
            <li className="mt-4">
              ✈️ <strong>Fly to Madrid (MAD).</strong> Fly into Madrid-Barajas
              Adolfo Suárez Airport (MAD) , Spain's largest airport, then
              connect to Marbella.
              <br />
              <br />
              🚅 Train: Take a high-speed AVE train from Madrid to Málaga (~2.5
              hours), then transfer to Marbella.
              <br />
              <br />
              🛩️ Domestic Flight: You can catch a 1-hour domestic flight from
              Madrid to Málaga Airport (AGP), then follow the options mentioned
              above.
              <br />
              <br />
              🚌 Bus or 🚙 Car: You can also take a long-distance bus or rent a
              car (about 5-6 hours).
            </li>
            <li className="mt-4">
              ✈️ <strong>Fly to Barcelona (BCN).</strong> Fly into Barcelona-El
              Prat Airport (BCN), then continue to Marbella.
              <br />
              <br />
              From Barcelona to Marbella:
              <br />
              🚅 Train: Take a high-speed train from Barcelona to Málaga (~6
              hours).
              <br />
              <br />
              🛩️ Domestic Flight: Fly from Barcelona to Málaga Airport (approx.
              1.5 hours).
            </li>
            <li className="mt-4">
              ✈️ <strong>Fly to London (LHR).</strong> Fly to London Heathrow
              (LHR), Gatwick (LGW), London City (LCY), or even Luton 😬 (LTN)
              and then take a connecting flight to Málaga.
              <br />
              <br />
              From London to Málaga:
              <br />
              🛩️ There are frequent flights from London to Málaga with British
              Airways, EasyJet, or Ryanair (~2.5 hours).
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default TravelAndLodging;
