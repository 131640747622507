import React from "react";
import DateTimeDisplay from "../components/dateTimeDisplay";
import { useCountdown } from "../services/useCountdown";

interface ExpiredNoticeProps {
  className?: string;
}
const ExpiredNotice: React.FC<ExpiredNoticeProps> = ({ className }) => {
  return (
    <div className={"expired-notice" + (className ? " " + className : "")}>
      <span>Expired!!!</span>
      <p>Please select a future date and time.</p>
    </div>
  );
};

interface ShowCounterProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  className?: string;
}
const ShowCounter: React.FC<ShowCounterProps> = ({
  days,
  hours,
  minutes,
  seconds,
  className,
}) => {
  return (
    <div className="show-counter">
      <a
        href="https://tapasadhikary.com"
        target="_blank"
        rel="noopener noreferrer"
        className={"countdown-link" + (className ? " " + className : "")}
      >
        <DateTimeDisplay value={days} type="Days" isDanger={days <= 3} />
        <p>:</p>
        <DateTimeDisplay value={hours} type="Hours" isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type="Mins" isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type="Seconds" isDanger={false} />
      </a>
    </div>
  );
};

interface CountdownTimerProps {
  targetDate: number;
  className?: string;
}
const CountdownTimer: React.FC<CountdownTimerProps> = ({
  targetDate,
  className,
}) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  // Check if the countdown has expired
  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice className={className} />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        className={className}
      />
    );
  }
};

export default CountdownTimer;
