import React from 'react';

const Schedule: React.FC = () => {
  return (
    <div className="text-content">
      <div className="simple-card p-1">
        <p className="fs-4 m-3">
          Some details still have to be ironed out, but we can share our
          tentative schedule:
        </p>
        <div className="mt-3 d-flex flex-column">
          <div className="d-flex justify-content-center">
            <img
              loading="lazy"
              className="list-item-img"
              src="/welcome-svgrepo-com.svg"
              alt="Spain"
            />
          </div>
          <h2 className="fw-bold">Viernes September 5th, 2025</h2>
          <div className="p-3">
            <h4 className="mb-3 fw-bold">Welcome Drinks</h4>
            <h5>7:30PM - 11:00PM</h5>
            <div className="mt-3">
              <a href="https://www.google.com/maps/dir//la+susana+marbella+address/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0xd72d92f1ac2685b:0x84ac3ace99c485?sa=X&ved=1t:3061&ictx=111">
                Balcon del golf, Urb. Golf Río Real, 85, 29603 Marbella, Málaga,
                Spain
              </a>
            </div>
            <div className="mt-3">Elegant Casual</div>
            <div className="mt-3">
              Meet and greet with a special surprise guest, everyone welcome!
            </div>
            <div className="mt-3">
              Optional - but highly encouraged - welcome party for all guests
              following rehearsal dinner. Open bar with selection of natural
              wines, beers, cocktails, mocktails, sangria, shots, etc. provided
              at TBD. please note: food will not be served, we recommend having
              dinner before.
            </div>
          </div>
        </div>
        <div className="mt-3 d-flex flex-column">
          <div className="d-flex justify-content-center">
            <img
              loading="lazy"
              className="list-item-img"
              src="/wedding-arch-svgrepo-com.svg"
              alt="Spain"
            />
          </div>
          <h2 className="fw-bold">Sabado September 6th, 2025</h2>
          <div className="p-3">
            <h4 className="mb-3 fw-bold">Wedding</h4>
            <h5>Maybe 4:00PM - 10:30PM</h5>
            <div className="mt-3">
              <a href="https://www.google.com/maps/dir//la+susana+marbella+address/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0xd72d92f1ac2685b:0x84ac3ace99c485?sa=X&ved=1t:3061&ictx=111">
                Balcon del golf, Urb. Golf Río Real, 85, 29603 Marbella, Málaga,
                Spain
              </a>
            </div>
            <div className="mt-2">
              Spanish Chic or Semi Formal Attire (Cowboy boots welcome). Inspo
              here:{" "}
              <a href="https://au.pinterest.com/dania3409/andalusian-wedding/">
                Pinterest
              </a>
            </div>
            <div className="mt-2">Transportation will be TBD.</div>
            <h4 className="mt-4 mb-0 fw-bold">After Wedding Celebration</h4>
            <h5 className="mb-3 fw-bold">
              and ceremonial kidnapping of the bride
            </h5>
            <h5>7:30PM - 1:53AM</h5>
            <div className="mt-3">
              <a href="https://www.google.com/maps/dir//la+susana+marbella+address/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0xd72d92f1ac2685b:0x84ac3ace99c485?sa=X&ved=1t:3061&ictx=111">
                Balcon del golf, Urb. Golf Río Real, 85, 29603 Marbella, Málaga,
                Spain
              </a>
            </div>
            <div className="mt-3">
              Celebration and libations continue into the night!
            </div>
          </div>
        </div>
        <div className="mt-3 d-flex flex-column">
          <div className="d-flex justify-content-center">
            <img
              loading="lazy"
              className="list-item-img"
              src="/beach-umbrella-svgrepo-com.svg"
              alt="Spain"
            />
          </div>
          <h2 className="fw-bold">Domingo September 7th, 2025</h2>
          <div className="p-3">
            <h4 className="mb-3 fw-bold">Day After Wedding Thing</h4>
            <h4>Maybe 11:30AM - 4:30PM</h4>
            <div className="mt-3">
              <a href="https://www.google.com/maps/dir//la+susana+marbella+address/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0xd72d92f1ac2685b:0x84ac3ace99c485?sa=X&ved=1t:3061&ictx=111">
                Balcon del golf, Urb. Golf Río Real, 85, 29603 Marbella, Málaga,
                Spain
              </a>
            </div>
            <div className="mt-3">
              Probably some brunch, mimosas, and hanging out by the pool and or
              beach!
              <br />
              <br />
              The father of the bride, Tim Beauchamp, has graciously volunteered
              to make the first 15 guests balloon animals!! (no marsupials).
              <div className="d-flex justify-content-center">
                <img
                  loading="lazy"
                  className="list-item-img"
                  src="/balloon-dog-small.svg"
                  alt="Spain"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
