import React from 'react';

const ThingsToDo: React.FC = () => {
  return (
    <div className="text-content p-3">
      <div className="simple-card w-100 p-0">
        <h2>Things to do</h2>
        <div className="text-start">
          <h4 className="mt-3 p-1">Turtles and Frogs:</h4>
          <img
            src="https://iberianatureforum.com/wp-content/uploads/2023/07/DSCN0998-001.jpg"
            alt="Frog"
            className="img-fluid mb-3"
          />
          <p className="p-1">
            There are turtles and several species of frogs indigineous to the
            costa del sol ecosystem.
            <br />
            We reccomend searching for these turltes and or frogs. If that's not
            your idea of a perfect day - like it is ours - there is plenty more
            that Marbella has to offer:{" "}
            <a href="https://veebrant.com/things-to-do-marbella/">
              Things to do in Marbella.
            </a>
          </p>
          <p className="p-1">
            <h4 className="mt-3 p-1">Wander the Old Town:</h4>
            <img
              src="thingsToDo/marbella-old-town-plaza.webp"
              alt="old town marbella"
              className="img-fluid mb-3"
            />
            If somehow you only have time for one quick stop in Marbella, let it
            be the charming old town. It's still a mystery to me why most
            visitors head straight to the busy seashore and ignore this area
            altogether. It's such a beautiful tangle of cobbled alleys and
            flower-filled squares, perfect for taking some cute Instagram
            photos, soaking up the vibe, and just wandering with no particular
            agenda in mind.
          </p>
          <p className="p-1">
            Stop by Plaza de los Naranjos, where a number of pavement cafés and
            restaurants serve traditional food and drinks in the shade of
            fragrant orange trees. Should you crave a mid-stroll snack, indulge
            in <em>churros con chocolate</em> at the historic{" "}
            <a href="https://churreriaramon.es/">Churrería Ramón</a>. Grab a
            seat on their bright blue terrace and watch the world go by. If
            you're lucky, you might even spot an alfresco flamenco show or a
            bohemian artist playing his rusty Spanish guitar nearby.
          </p>
          <p className="p-1">
            Spain's tapas culture is alive and well along the{" "}
            <em>casco antiguo</em>'s slim, bougainvillea-draped backstreets, and
            so is its Arab heritage – intricate mosaic lamps light up the
            storefronts, colorful silk scarves and rugs ruffle with the breeze,
            and an intoxicating scent of dark musk and incense wafts through the
            air.
          </p>
          <p className="p-1">
            <h4 className="mt-3 p-1">Have a Cocktail at Gaugin:</h4>
            <img
              src="https://mldvwwasb8tu.i.optimole.com/w:813/h:1080/q:90/f:best/ig:avif/https://veebrant.com/wp-content/uploads/2023/08/gauguin-marbella.jpg"
              alt="Gaugin marbella"
              className="img-fluid mb-3"
            />
            It takes a certain type of bar to make me a regular. It's not just
            the drinks or the decor. It's not even the vibe, or the music, or
            the crowd. It's the magical alchemy of all those things, and that's
            what you'll find at{" "}
            <a href="https://www.instagram.com/gauguinmarbella/">Gauguin</a>, a
            characterful tiki bar adorned with Polynesian-inspired designs down
            a restaurant-lined street in the city center.
          </p>
          <p className="p-1">
            Pull up a stool at the bamboo-framed bar or take a seat at a
            sidewalk table, and you'll understand why it's survived in Marbella
            longer than others. It's an absolute gem to stop by on your way to
            or from dinner or even to spend an entire evening catching up with
            your friends or mingling with the cool locals over fruity
            rum-infused libations served in ceramic coconut cups and sculptural
            mugs depicting mythical creatures.
          </p>
          <p className="p-1">
            <h4 className="mt-3 p-1">Take a Tapas Tour:</h4>
            <img
              src="https://mldvwwasb8tu.i.optimole.com/w:745/h:1080/q:90/f:best/ig:avif/https://veebrant.com/wp-content/uploads/2023/08/marbella-tapas-street.jpg"
              alt="Tapas marbella"
              className="img-fluid mb-3"
            />
            You can't really say you've experienced Spain properly until you've
            tasted your way through a couple of tapas bars. Marbella has plenty,
            but the last thing you want is to end up in a touristy food trap,
            eating a mediocre meal and paying too much for it.
          </p>
          <p className="p-1">
            My best advice here is to follow the locals, most specifically the
            knowledgeable guys at Taste Marbella, who will take you on a
            delightful evening tapas adventure through the old town. This{" "}
            <a href="https://go.skimresources.com?id=106559X1664337&amp;xs=1&amp;url=https%3A%2F%2Fwww.viator.com%2Ftours%2FMarbella%2FOriginal-Marbella-Tapas-Adventure%2Fd22304-129645P1">
              expert-guided food tour
            </a>{" "}
            starts in Plaza de los Naranjos and includes three delicious stops
            at some beloved local institutions, along with a healthy dose of
            culture and sightseeing through <em>casco antiguo</em>'s
            whitewashed, maze-like lanes.
          </p>
          <p className="p-1">
            <h4 className="mt-3 p-1">Walk the Golden Mile:</h4>
            <img
              src="https://mldvwwasb8tu.i.optimole.com/w:1300/h:867/q:90/f:best/ig:avif/https://veebrant.com/wp-content/uploads/2023/08/marbella-golden-mile-walk.jpg"
              alt="Golden Mile marbella"
              className="img-fluid mb-3"
            />
            Walking along the Golden Mile is unquestionably one of the best
            things to do in Marbella. Although a natural extension of the louder
            and more familiar Paseo Maritimo, this stretch of coastline has a
            decidedly different vibe. Vistas are wider, restaurants are fewer
            but fancier, and elegant waterfront homes and{" "}
            <a href="https://veebrant.com/luxury-villas-marbella/">
              upmarket holiday villas
            </a>{" "}
            take the place of sprawling chain hotels and nondescriptive
            apartment buildings.
          </p>
          <p className="p-1">
            <h4 className="mt-3 p-1">Spend an evening at Puente Romano:</h4>
            <img
              src="https://mldvwwasb8tu.i.optimole.com/w:809/h:1080/q:90/f:best/ig:avif/https://veebrant.com/wp-content/uploads/2023/08/pr-evening-out.jpg"
              alt="Puente Romano"
              className="img-fluid mb-3"
            />
            Even in a town packed with upscale diversions, spending an evening
            at <a href="https://www.puenteromano.com/">Puente Romano</a> is
            still one of the best experiences you can have in Marbella. Designed
            to resemble a typical Andalusian village, the resort is absurdly
            idyllic, with footpaths meandering through lush subtropical gardens,
            past turquoise pools, lily ponds, a family of ducks, and low-rise
            whitewashed, terracotta-roofed buildings. It is a destination in
            itself, a vibrant and happening place where the rich and famous
            check in and out, mingle, and play throughout the year.
          </p>
          <p className="p-1">
            <h4 className="mt-3 p-1">Hike La Concha:</h4>
            <img
              src="https://mldvwwasb8tu.i.optimole.com/w:720/h:1080/q:90/f:best/ig:avif/https://veebrant.com/wp-content/uploads/2023/08/la-concha-mountain.jpg"
              alt="La Concha"
              className="img-fluid mb-3"
            />
            Hiking La Concha is a must-do for any nature lover visiting
            Marbella, so put on some sunscreen, pack plenty of water, lace up
            your boots, and embark on an exhilarating outdoor escapade. It's not
            an easy walk, but the views from the top make it all worthwhile.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThingsToDo;
