import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { addAddress } from "../services/firebase";
import { AddressType } from "../types/types";
import { db } from "../services/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { validateField } from "../services/validator";

const Addresses: React.FC = () => {
  const [formData, setFormData] = useState<AddressType>({
    names: [""], // Default to one name field
    streetAddress: "",
    addressLine2: "",
    country: "",
    city: "",
    state: "",
    zipCode: "",
    email: "",
    mobile: "",
    numOfPeople: 0, // Default number of people to 0
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [successMessage, setSuccessMessage] = useState("");
  const [addresses, setAddresses] = useState<AddressType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getAddresses = async () => {
      const addressesCollectionRef = collection(db, "addresses");
      const data = await getDocs(addressesCollectionRef);
      console.log("Successfully got addresses: \n", data);
      setAddresses(data.docs.map((doc) => ({ ...doc.data() } as AddressType)));
    };

    getAddresses();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    // Handle change for the number of people
    if (name === "numOfPeople") {
      const num = parseInt(value, 10);
      setFormData({
        ...formData,
        numOfPeople: num,
        names: Array(num).fill(""),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

      let error = validateField(name, value);
      setErrors({
        ...errors,
        [name]: error,
      });
    }
  };

  const handleNameChange = (index: number, value: string) => {
    const updatedNames = [...formData.names];
    updatedNames[index] = value;

    setFormData({
      ...formData,
      names: updatedNames,
    });

    // Validate name
    if (!value) {
      setErrors({ ...errors, [`name-${index}`]: "Name is required" });
    } else {
      setErrors({ ...errors, [`name-${index}`]: "" });
    }
  };

  const handlePhoneChange = (value: string | undefined) => {
    setFormData({
      ...formData,
      mobile: value || "",
    });

    // Validate mobile number
    if (!value) {
      setErrors({ ...errors, mobile: "Valid mobile number is required" });
    } else {
      setErrors({ ...errors, mobile: "" });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    // if no errors, submit the form
    const hasErrors = Object.values(errors).some((error) => error);
    if (!hasErrors) {
      try {
        await addAddress(formData);
        setSuccessMessage("Address submitted successfully!");
        // reset the form
        setFormData({
          names: [""],
          streetAddress: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zipCode: "",
          email: "",
          mobile: "",
          numOfPeople: 0,
        });
        setErrors({});
      } catch (error) {
        setIsLoading(false);
        console.error("Failed to submit address:", error);
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="text-content">
      <h1 className="mb-1">Add Your Address</h1>
      <h4 className="mb-2">so we can send you the official invite</h4>
      {successMessage && <p className="success">{successMessage}</p>}
      <form className="simple-card" onSubmit={handleSubmit}>
        <div className="mb-3">
          {formData.numOfPeople === 0 && (
            <img
              className="img-fluid w-100 h-75 mb-4 frame grayscale pulse"
              src="/images/alan_pensive.webp"
              alt="How many guests?"
            />
          )}
          <label htmlFor="numOfPeople" className="form-label fw-bold mt-2">
            How many people would you like on the invite?
          </label>
          <select
            id="numOfPeople"
            className="form-select"
            name="numOfPeople"
            value={formData.numOfPeople}
            onChange={handleChange}
            required
          >
            {Array.from({ length: 7 }, (_, i) => (
              <option key={i} value={i}>
                {`${i}`}
              </option>
            ))}
          </select>
        </div>

        {formData.numOfPeople > 0 && (
          <>
            {/* Name fields based on number of people */}
            {formData.names.map((name, index) => (
              <div className="mb-3 text-start" key={index}>
                <label className="form-label fw-bold">Name {index + 1}:</label>
                <input
                  className="form-control"
                  type="text"
                  value={name}
                  onChange={(e) => handleNameChange(index, e.target.value)}
                  required
                />
                {errors[`name-${index}`] && (
                  <div className="text-danger">{errors[`name-${index}`]}</div>
                )}
              </div>
            ))}
            <div className="mb-3 text-start">
              <label className="form-label fw-bold">Street Address:</label>
              <input
                className="form-control"
                type="text"
                name="streetAddress"
                value={formData.streetAddress}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3 text-start">
              <label className="form-label fw-bold">
                Address Line Two (Optional):
              </label>
              <input
                className="form-control"
                type="text"
                name="addressLine2"
                value={formData.addressLine2}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 text-start text-start">
              <label className="form-label fw-bold">Country:</label>
              <select
                className="form-select"
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
              >
                <option value="">Select Country</option>
                <option value="USA">USA</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Spain">Spain</option>
                <option value="Monaco">Monaco</option>
                <option value="Iraq">Iraq</option>
                <option value="Luxembourg">Luxembourg</option>
              </select>
            </div>
            <div className="mb-3 text-start">
              <label className="form-label fw-bold">City/Town/Borough:</label>
              <input
                className="form-control"
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
              />
              {errors.city && <p className="error">{errors.city}</p>}
            </div>
            <div className="mb-3 text-start">
              <label className="form-label fw-bold">
                State/Region/Province:
              </label>
              <input
                className="form-control"
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                required
              />
              {errors.state && <p className="error">{errors.state}</p>}
            </div>
            <div className="mb-3 text-start">
              <label className="form-label fw-bold">Zip/Postal Code:</label>
              <input
                className="form-control"
                type="text"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
                required
              />
              {errors.zipCode && <p className="error">{errors.zipCode}</p>}
            </div>
            {/* Email */}
            <div className="mb-3 text-start">
              <label className="form-label fw-bold">Email:</label>
              <input
                className="form-control"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            {/* Mobile */}
            <div className="mb-3 text-start">
              <label className="form-label fw-bold">Mobile:</label>
              <PhoneInput
                placeholder="Enter phone number"
                value={formData.mobile}
                onChange={handlePhoneChange}
                defaultCountry="US"
                required
              />
              {errors.mobile && <p className="error">{errors.mobile}</p>}
            </div>
            <button
              type="submit"
              className="btn btn-secondary mt-3"
              disabled={isLoading}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          </>
        )}
      </form>

      <div className="simple-card mt-5">
        <h2>Folks who have responded:</h2>
        {addresses.map((address, index) => (
          <div key={index} className="p-3 fw-normal">
            {address.names.join(", ")}
            <br />
            {address.city}, {address.state}, {address.country}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Addresses;
