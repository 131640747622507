import React from 'react';
import PageUnderConstructionNotice from "../components/underConstruction";

const RSVP: React.FC = () => {
  return (
    <div className="text-content">
      <div className=" simple-card">
        <h2>
          <PageUnderConstructionNotice
            title="RSVP page is coming soon!"
            message={
              <>
                Right now we're collecting everyone's addresses so we can send
                out invites. Visit the{" "}
                <a href="/addresses" className="text-link">
                  Address Page
                </a>{" "}
                to qualify.
              </>
            }
          />
        </h2>
      </div>
    </div>
  );
};

export default RSVP;
