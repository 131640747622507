import React from "react";
import { useParallax } from "react-scroll-parallax";

interface AnimationGrowProps {
  text?: string;
  className?: string;
}

const AnimationGrow: React.FC<AnimationGrowProps> = ({
  text = "Default Text",
  className = "",
}) => {
  const easeInQuadParallax = useParallax<HTMLHeadingElement>({
    scale: [1, 1.25, "easeInQuad"],
  });
  return (
    <div ref={easeInQuadParallax.ref} className={`${className}`}>
      {text}
    </div>
  );
};

export default AnimationGrow;
