import { collection, addDoc } from 'firebase/firestore';
import { AddressType } from '../types/types';
import { db } from './firebaseConfig';


const addressesCollectionRef = collection(db, "addresses");

// Add an address
export const addAddress = async (address: AddressType) => {
  try {
    await addDoc(addressesCollectionRef, address);
    console.log('Address added successfully! \n', address);
  } catch (error) {
    console.error('Error adding address: ', error);
  }
};
