import React from "react";
import { useParallax } from "react-scroll-parallax";

interface AnimationSpinProps {
  imgSrc?: string;
  className?: string;
}

const AnimationSpin: React.FC<AnimationSpinProps> = ({
  imgSrc = "",
  className = "",
}) => {
  const easeInQuadParallax = useParallax<HTMLImageElement>({
    rotate: [0, 720],
  });
  return (
    <img
      ref={easeInQuadParallax.ref}
      src={imgSrc}
      className={className}
      alt="spinning"
      loading="lazy"
    />
  );
};

export default AnimationSpin;
