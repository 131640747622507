import React from "react";
import PageUnderConstructionNotice from "../components/underConstruction";

const Registry: React.FC = () => {
  return (
    <div className="text-content">
      <div className="simple-card">
        <PageUnderConstructionNotice
          title="Registry page is coming soon!"
          message={
            <>
              As soon as Owen & Alan have time.
              <br />
              <br />
              Currently Owen has no idea how he's going to implement this
              page...
              <br />
              <br />
              Pay for a payment processing integration like Stripe? Why didn't
              he just use zola 🙃
            </>
          }
        />
      </div>
    </div>
  );
};

export default Registry;
