import validator from 'validator';

export const validateField = (name: string, value: string) => {
    let error = '';

    switch (name) {
      case 'streetAddress':
        if (!value) {
          error = 'Street address is required';
        }
        break;
      case 'city':
        if (!value) {
          error = 'City is required';
        }
        break;
      case 'state':
        if (!value) {
          error = 'State/Region is required';
        }
        break;
      case 'zipCode':
        if (
          !/^\d{5}(-\d{4})?$/.test(value) && // US ZIP code (e.g., 12345 or 12345-6789)
          !/^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i.test(value) // UK Postcode (e.g., SW1A 1AA)
        ) {
          error = 'ZIP code or postcode must be valid';
        }
        break;
      case 'email':
        if (!validator.isEmail(value)) {
          error = 'Email must be valid';
        }
        break;
      default:
        error = '';
    }

    return error;
}


export const createTextForNumber = (num: number) => {
  let returnText = '';

  switch (num) {
    case 1:
      returnText = 'or (1/2 + 1/4 + 1/8...)';
      break;
    case 2:
      returnText = 'or (2!!)';
      break;
    case 3:
      returnText = 'or (4^3 + 4^3 + (-5)^3)';
      break;
    case 4:
      returnText = 'or (∫₀^π/₂ 4 cos(x) dx)';
      break;
    case 5:
      returnText = 'or (sqrt(13^2 - 12^2))';
      break;
    case 6:
      returnText = 'or (3!)';
      break;
    default:
      returnText = '';
  }

  return returnText;
};
