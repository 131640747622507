import React, { useEffect } from "react";
import Slider, { CustomArrowProps } from "react-slick";
import "../App.css";
import AnimationGrow from "src/components/animationGrow";
import AnimationSpin from "src/components/animationSpin";
import AnimationRotate from "src/components/animationRotate";
import CountdownTimer from "src/components/countdownTimer";

const images = [
  "/images/looking_lovingly.webp",
  "/images/lauren_sad.webp",
  // "/images/from_behind.webp",
  "/images/lauren_hoisted.webp",
  "/images/alan_come_sit.webp",
  // "/images/lauren_on_ground.webp",
  "/images/lauren_proposing.webp",
  "/images/looking_at_camera.webp",
  // "/images/kissing.webp",
  // "/images/sniffing_fingers.webp",
];

// Define the types for the arrow components using React Slick's CustomArrowProps
function SampleNextArrow(props: CustomArrowProps) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: CustomArrowProps) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const Home: React.FC = () => {
  useEffect(() => {
    // Preload images on component mount
    preloadImages(images);
  }, []);

  const preloadImages = (imageUrls: string[]) => {
    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: (i: number) => (
      <img
        src={images[i]}
        alt={`Thumbnail ${i}`}
        className={`thumbnail-image`}
      />
    ),
    dotsClass: "custom-thumbnail-dots",
  };

  const weddingDate: number = new Date(2025, 8, 6, 15, 0, 0).getTime();

  return (
    <div>
      {/* Carousel */}
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt={`Slide ${index}`}
              className="carousel-image"
            />
          </div>
        ))}
      </Slider>
      <div className="text-content">
        <CountdownTimer targetDate={weddingDate} className="mb-4" />
        <AnimationGrow
          text="Lauren Beauchamp & Owen James"
          className="tangerine-bold mb-4 large-font"
        />
        <h2>September 6, 2025</h2>
        <h3>
          <a href="https://www.lasusanamarbella.com/">La Susana</a>, Marbella,
          Spain
        </h3>
        <ul className="standout-card">
          <div className="column">
            <div className="list-item">
              <img
                loading="lazy"
                className="list-item-img"
                src="/spain-map-svgrepo-com.svg"
                alt="Spain"
              />
              <div className="list-item-title">Spain</div>
              <div className="list-item-text">
                Come join us in sun-drenched Marbella, Spain
              </div>
            </div>
            <div className="list-item">
              <img
                loading="lazy"
                className="list-item-img"
                src="https://static.wixstatic.com/media/8ef0f2_0d9ac8b1287e45369936584dec3c86c5~mv2.png/v1/fill/w_222,h_242,al_c,lg_1,q_85,enc_auto/Pool.png"
                alt="Pools"
              />
              <div className="list-item-title">2 Swimming Pools</div>
              <div className="list-item-text">
                Double the swimming pools double the atmospheres
              </div>
            </div>
            <div className="list-item">
              <AnimationRotate
                imgSrc="https://static.wixstatic.com/media/8ef0f2_5cbc5f548a9a422a80acb84d1bd2ba6e~mv2.png/v1/crop/x_21,y_0,w_203,h_222/fill/w_222,h_242,al_c,lg_1,q_85,enc_auto/beach_plot.png"
                className="list-item-img"
              />
              <div className="list-item-title">Space!</div>
              <div className="list-item-text">
                The wedding venue encompasses 96,875ft
                <sup>2</sup>, and includes a spacious private beach deck (5
                minutes drive).
              </div>
            </div>
          </div>
          <div className="column">
            <div className="list-item">
              <img
                loading="lazy"
                className="list-item-img"
                src="/wedding-arch-svgrepo-com.svg"
                alt="Rooms"
              />
              <div className="list-item-title">Wedding!</div>
              <div className="list-item-text">Watch two people wed.</div>
              <div className="list-item-text">
                Don't worry! It won't be religious.
              </div>
            </div>
            <div className="list-item">
              <img
                loading="lazy"
                className="list-item-img"
                src="https://static.wixstatic.com/media/8ef0f2_08aa2c6890c8495aa870b73e57a6e6a1~mv2.png/v1/crop/x_0,y_0,w_436,h_475/fill/w_222,h_242,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/beach_house.png"
                alt="Beach House"
              />
              <div className="list-item-title">Beach House</div>
              <div className="list-item-text">A beach house!</div>
            </div>
            <div className="list-item">
              <AnimationSpin
                imgSrc="https://static.wixstatic.com/media/8ef0f2_639a4e6dbc3c4dca9ec39880873890ba~mv2.png/v1/crop/x_233,y_264,w_763,h_832/fill/w_222,h_242,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/110545423-ic%C3%B4ne-de-boule-disco-fine-ligne-sur-fond-blanc_edited.png"
                className="list-item-img"
              />
              <div className="list-item-title">Night Club on Property</div>
              <div className="list-item-text">
                With a bar. With drinks! Lauren has been working really hard on
                her dance.
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Home;
