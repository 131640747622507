import React from "react";

const WeddingParty: React.FC = () => {
  return (
    <div className="text-content">
      <div className="simple-card">
        <h2>Wedding Party</h2>
        <p>
          The wedding party and +1's will be staying on-venue where they can
          enjoy all that the sick ass venue has to offer
        </p>
        <ul className="standout-card">
          <div className="column">
            <div className="list-item">
              <img
                loading="lazy"
                className="list-item-img"
                src="https://static.wixstatic.com/media/8ef0f2_45552d3f5e3749e9b33433fe74a692a0~mv2.png/v1/fill/w_222,h_242,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/houses%20copie.png"
                alt="House"
              ></img>
              <div className="list-item-title">3 Luxurious Vilas</div>
              <div className="list-item-text">
                With breathtaking views overlooking of Costa del Sol
              </div>
            </div>
            <div className="list-item">
              <img
                loading="lazy"
                className="list-item-img"
                src="https://static.wixstatic.com/media/8ef0f2_0d9ac8b1287e45369936584dec3c86c5~mv2.png/v1/fill/w_222,h_242,al_c,lg_1,q_85,enc_auto/Pool.png"
                alt="Pools"
              ></img>
              <div className="list-item-title">2 Swimming Pools</div>
              <div className="list-item-text">
                Double the swimming pools double the atmospheres
              </div>
            </div>
            <div className="list-item">
              <img
                loading="lazy"
                className="list-item-img"
                src="https://static.wixstatic.com/media/8ef0f2_5cbc5f548a9a422a80acb84d1bd2ba6e~mv2.png/v1/crop/x_21,y_0,w_203,h_222/fill/w_222,h_242,al_c,lg_1,q_85,enc_auto/beach_plot.png"
                alt="Beach"
              ></img>
              <div className="list-item-title">Space!</div>
              <div className="list-item-text">
                The wedding venue is 9,000 sqm; or 96,875 sq. ft in freedom
                units. Includes a spacious private, beach deck (5 mins drive)
              </div>
            </div>
            <div className="list-item">
              <img
                loading="lazy"
                className="list-item-img"
                src="https://static.wixstatic.com/media/8ef0f2_639a4e6dbc3c4dca9ec39880873890ba~mv2.png/v1/crop/x_233,y_264,w_763,h_832/fill/w_222,h_242,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/110545423-ic%C3%B4ne-de-boule-disco-fine-ligne-sur-fond-blanc_edited.png"
                alt="Club"
              ></img>
              <div className="list-item-title">Night Club on Property</div>
              <div className="list-item-text">
                Soundproofed nightclub with bar
              </div>
            </div>
          </div>
          <div className="column">
            <div className="list-item">
              <img
                loading="lazy"
                className="list-item-img"
                src="https://static.wixstatic.com/media/8ef0f2_a3028cde6906483db3219bdc822b31f1~mv2.png/v1/fill/w_222,h_242,al_c,lg_1,q_85,enc_auto/rooms.png"
                alt="Rooms"
              ></img>
              <div className="list-item-title">Rooms!</div>
              <div className="list-item-text">29 Total Bedrooms</div>
              <div className="list-item-text">25 Double rooms</div>
              <div className="list-item-text">2 "Family" Rooms</div>
              <div className="list-item-text">2 Dormitories (Sierra Madre)</div>
            </div>
            <div className="list-item">
              <img
                loading="lazy"
                className="list-item-img"
                src="https://static.wixstatic.com/media/8ef0f2_e355f030be544afb88a261bad615ebcf~mv2.png/v1/fill/w_222,h_242,al_c,lg_1,q_85,enc_auto/garden.png"
                alt="Garden"
              ></img>
              <div className="list-item-title">Garden</div>
              <div className="list-item-text">
                Perfect to discuss secret plans
              </div>
            </div>
            <div className="list-item">
              <img
                loading="lazy"
                className="list-item-img"
                src="https://static.wixstatic.com/media/8ef0f2_08aa2c6890c8495aa870b73e57a6e6a1~mv2.png/v1/crop/x_0,y_0,w_436,h_475/fill/w_222,h_242,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/beach_house.png"
                alt="Beach House"
              ></img>
              <div className="list-item-title">Beach House</div>
              <div className="list-item-text">
                A beach house with direct access to the beach (no rooms)
              </div>
            </div>
            <div className="list-item">
              <img
                loading="lazy"
                className="list-item-img"
                src="https://static.wixstatic.com/media/8ef0f2_b736fd65366d461794a1a48fb7780cff~mv2.png/v1/fill/w_222,h_242,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/7164237.png"
                alt="Massage"
              ></img>
              <div className="list-item-title">Massage Stuff!</div>
              <div className="list-item-text">
                Massage room (massages are not included)
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default WeddingParty;
