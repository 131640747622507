import React from "react";

interface PageUnderConstructionProps {
  image?: string;
  title?: string;
  message?: React.ReactNode | string;
}

const PageUnderConstructionNotice: React.FC<PageUnderConstructionProps> = ({
  image = "/images/alan_asleep.webp", // Default image source
  title = "Page Under Construction",
  message = "We're working hard to bring this page to life. Please check back later!",
}) => {
  return (
    <div>
      <img
        src={image} // Use the passed image or the default one
        alt="Under Construction"
        className="img-fluid w-100 h-75 mb-4 frame pulse"
      />
      <h2 className="p-3 mb-0">{title}</h2>
      <p>{message}</p>
    </div>
  );
};

export default PageUnderConstructionNotice;
