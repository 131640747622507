import React from "react";
import { useParallax } from "react-scroll-parallax";

interface AnimationRotateProps {
  imgSrc?: string;
  className?: string;
}

const AnimationRotate: React.FC<AnimationRotateProps> = ({
  imgSrc = "",
  className = "",
}) => {
  const easeInQuadParallax = useParallax<HTMLImageElement>({
    rotateY: [0, 720],
  });
  return (
    <img
      ref={easeInQuadParallax.ref}
      src={imgSrc}
      className={className}
      alt="rotating"
      loading="lazy"
    />
  );
};

export default AnimationRotate;
